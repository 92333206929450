import React from 'react';

import { Drops } from '../components/Drops';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const DropPage = () => (
  <Layout>
    <SEO title="Drops" />
    <Drops />
  </Layout>
);

export default DropPage;
